import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FiEdit2, FiTrash2, FiEye, FiEyeOff, FiSearch, FiFilter } from 'react-icons/fi';
import AdminLayout from '../../components/Admin/AdminLayout';
import { getPosts, deletePost, updatePost } from '../../services/blogService';
import { toast } from 'react-toastify';

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes['2xl']};
  margin: 0;
`;

const ActionBar = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 8px;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SearchInput = styled.input`
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1rem;
  width: 100%;
  &:focus {
    outline: none;
  }
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: ${({ theme, $active }) => $active ? theme.colors.primary : theme.colors.surface};
  color: ${({ theme, $active }) => $active ? 'white' : theme.colors.text};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    background: ${({ theme, $active }) => $active ? theme.colors.primary : theme.colors.surfaceHover};
  }
`;

const NewPostButton = styled.button`
  padding: 0.75rem 1.5rem;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
  }
`;

const PostGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const PostCard = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
`;

const StatusBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.875rem;
  background: ${({ theme, $published }) => 
    $published ? theme.colors.success + '20' : theme.colors.warning + '20'};
  color: ${({ theme, $published }) => 
    $published ? theme.colors.success : theme.colors.warning};
`;

const PostTitle = styled.h3`
  margin: 0 0 1rem 0;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
`;

const PostMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const PostDate = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  margin: 0;
`;

const PostTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const Tag = styled.span`
  background: ${({ theme }) => theme.colors.surfaceHover};
  color: ${({ theme }) => theme.colors.textSecondary};
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.75rem;
`;

const PostActions = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-end;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textSecondary};
  transition: all 0.2s ease;

  &:hover {
    color: ${({ theme, $delete }) => $delete ? theme.colors.error : theme.colors.primary};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all'); // 'all', 'published', 'draft'
  const navigate = useNavigate();

  const fetchPosts = useCallback(async () => {
    try {
      setLoading(true);
      const fetchedPosts = await getPosts({ status: filter === 'all' ? null : filter });
      setPosts(fetchedPosts);
    } catch (error) {
      toast.error('Error fetching posts: ' + error.message);
    } finally {
      setLoading(false);
    }
  }, [filter]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const handleDelete = async (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await deletePost(postId);
        setPosts(posts.filter(post => post.id !== postId));
        toast.success('Post deleted successfully');
      } catch (error) {
        toast.error('Error deleting post: ' + error.message);
      }
    }
  };

  const togglePublished = async (post) => {
    try {
      const newStatus = post.status === 'published' ? 'draft' : 'published';
      await updatePost(post.id, { ...post, status: newStatus });
      setPosts(posts.map(p => 
        p.id === post.id 
          ? { ...p, status: newStatus } 
          : p
      ));
      toast.success(`Post ${newStatus === 'published' ? 'published' : 'unpublished'} successfully`);
    } catch (error) {
      toast.error('Error updating post: ' + error.message);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <AdminLayout>
      <Container>
        <Header>
          <Title>Blog Posts</Title>
          <ActionBar>
            <NewPostButton onClick={() => navigate('/admin/blog/new')}>
              + New Post
            </NewPostButton>
          </ActionBar>
        </Header>

        <SearchBar>
          <FiSearch size={20} color={theme => theme.colors.textSecondary} />
          <SearchInput
            type="text"
            placeholder="Search posts by title or tags..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchBar>

        <div style={{ marginBottom: '1rem', display: 'flex', gap: '0.5rem' }}>
          <FilterButton
            $active={filter === 'all'}
            onClick={() => setFilter('all')}
          >
            <FiFilter /> All
          </FilterButton>
          <FilterButton
            $active={filter === 'published'}
            onClick={() => setFilter('published')}
          >
            <FiEye /> Published
          </FilterButton>
          <FilterButton
            $active={filter === 'draft'}
            onClick={() => setFilter('draft')}
          >
            <FiEyeOff /> Drafts
          </FilterButton>
        </div>

        {loading ? (
          <EmptyState>Loading posts...</EmptyState>
        ) : filteredPosts.length === 0 ? (
          <EmptyState>
            {searchTerm
              ? 'No posts found matching your search.'
              : 'No posts yet. Create your first post!'}
          </EmptyState>
        ) : (
          <PostGrid>
            {filteredPosts.map(post => (
              <PostCard key={post.id}>
                <StatusBadge $published={post.status === 'published'}>
                  {post.status === 'published' ? 'Published' : 'Draft'}
                </StatusBadge>
                <PostTitle>{post.title}</PostTitle>
                <PostMeta>
                  <PostDate>Created: {formatDate(post.createdAt)}</PostDate>
                  {post.updatedAt && (
                    <PostDate>Updated: {formatDate(post.updatedAt)}</PostDate>
                  )}
                  {post.tags && post.tags.length > 0 && (
                    <PostTags>
                      {post.tags.map(tag => (
                        <Tag key={tag}>{tag}</Tag>
                      ))}
                    </PostTags>
                  )}
                </PostMeta>
                <PostActions>
                  <ActionButton 
                    onClick={() => togglePublished(post)}
                    title={post.status === 'published' ? 'Unpublish' : 'Publish'}
                  >
                    {post.status === 'published' ? <FiEyeOff size={20} /> : <FiEye size={20} />}
                  </ActionButton>
                  <ActionButton
                    onClick={() => navigate(`/admin/blog/edit/${post.id}`)}
                    title="Edit post"
                  >
                    <FiEdit2 size={20} />
                  </ActionButton>
                  <ActionButton
                    $delete
                    onClick={() => handleDelete(post.id)}
                    title="Delete post"
                  >
                    <FiTrash2 size={20} />
                  </ActionButton>
                </PostActions>
              </PostCard>
            ))}
          </PostGrid>
        )}
      </Container>
    </AdminLayout>
  );
};

export default BlogList;
