import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import AdminNavbar from './AdminNavbar';

const LayoutContainer = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  padding-top: 70px;
`;

const MainContent = styled.main`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 992px) {
    padding: 1rem;
  }
`;

const AdminLayout = ({ children }) => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <>
      <AdminNavbar onLogout={handleLogout} />
      <LayoutContainer>
        <MainContent>
          {children}
        </MainContent>
      </LayoutContainer>
    </>
  );
};

export default AdminLayout;
