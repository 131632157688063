import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import Typewriter from 'typewriter-effect';
import { Link } from 'react-router-dom';

const HeroNew_v2 = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const skills = [
    "Machine Learning",
    "Deep Learning",
    "Computer Vision",
    "Natural Language Processing",
    "Data Science",
    "Python",
    "TensorFlow",
    "PyTorch"
  ];

  return (
    <HeroContainer>
      <BackgroundAnimation />
      <ContentWrapper>
        <LeftSection
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <WelcomeText>Hello, I'm</WelcomeText>
          <GradientName>Vinay Prabhakar</GradientName>
          <TypewriterContainer>
            <Typewriter
              options={{
                strings: [
                  'Machine Learning Engineer',
                  'Data Scientist',
                  'AI Researcher',
                  'Problem Solver'
                ],
                autoStart: true,
                loop: true,
                deleteSpeed: 50,
                delay: 80,
              }}
            />
          </TypewriterContainer>
          <Description>
            Passionate about transforming complex data into meaningful insights and building intelligent systems that make a difference.
          </Description>
          <SocialLinks>
            <SocialIcon href="https://github.com/VinayPrabhakarX" target="_blank">
              <FaGithub />
            </SocialIcon>
            <SocialIcon href="https://linkedin.com/in/VinayPrabhakarX" target="_blank">
              <FaLinkedin />
            </SocialIcon>
            <SocialIcon href="https://x.com/VinayPrabhakarX" target="_blank">
              <FaXTwitter />
            </SocialIcon>
          </SocialLinks>
          <CTAButton
            as={Link}
            to="/contact"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Let's Connect
          </CTAButton>
        </LeftSection>

        <RightSection>
          <SkillsCloud>
            {skills.map((skill, index) => (
              <SkillBadge
                key={skill}
                style={{
                  '--delay': `${index * 0.1}s`,
                  '--rotation': `${Math.random() * 360}deg`
                }}
              >
                {skill}
              </SkillBadge>
            ))}
          </SkillsCloud>
        </RightSection>
      </ContentWrapper>
    </HeroContainer>
  );
};

const floatAnimation = keyframes`
  0% { 
    transform: translateY(0px) rotate(0deg);
    background-position: 0% 50%;
  }
  50% { 
    transform: translateY(-20px) rotate(5deg);
    background-position: 100% 50%;
  }
  100% { 
    transform: translateY(0px) rotate(0deg);
    background-position: 0% 50%;
  }
`;

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 5px rgba(106, 17, 203, 0.2); }
  50% { box-shadow: 0 0 20px rgba(106, 17, 203, 0.4); }
  100% { box-shadow: 0 0 5px rgba(106, 17, 203, 0.2); }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const particleAnimation = keyframes`
  0% { transform: translate(0, 0); }
  100% { transform: translate(var(--end-x), var(--end-y)); }
`;

const BackgroundAnimation = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.gradients.primary};
    opacity: 0.1;
    animation: ${gradientAnimation} 15s ease infinite;
  }
`;

const HeroContainer = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 968px) {
    flex-direction: column;
    text-align: center;
  }
`;

const LeftSection = styled(motion.div)`
  flex: 1;
  z-index: 1;
`;

const WelcomeText = styled.h2`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 1rem;
`;

const GradientName = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  background: ${({ theme }) => theme.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: ${gradientAnimation} 4s ease infinite;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const TypewriterContainer = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 1rem;

  .Typewriter__wrapper {
    color: ${({ theme }) => theme.colors.text};
  }

  .Typewriter__cursor {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 2rem;
  line-height: 1.6;
  max-width: 600px;

  @media (max-width: 968px) {
    margin: 0 auto 2rem;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (max-width: 968px) {
    justify-content: center;
  }
`;

const SocialIcon = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    transform: translateY(-3px);
  }
`;

const CTAButton = styled(motion.button)`
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 25px;
  background: ${({ theme }) => theme.gradients.primary};
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background: ${({ theme }) => theme.gradients.primaryHover};
    box-shadow: ${({ theme }) => theme.shadows.primaryGlow};
  }
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SkillsCloud = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const SkillBadge = styled.span`
  padding: 0.7rem 1.2rem;
  background: ${({ theme }) => theme.gradients.primaryTransparent};
  color: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => `${theme.colors.primary}50`};
  border-radius: 20px;
  font-size: 0.9rem;
  white-space: nowrap;
  position: relative;
  animation: ${floatAnimation} 3s ease-in-out infinite;
  animation-delay: var(--delay);
  transform-origin: center;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.gradients.primary};
    color: white;
    border-color: transparent;
    box-shadow: ${({ theme }) => theme.shadows.primaryGlow};
  }
`;

export default HeroNew_v2;
