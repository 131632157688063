import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { projectService } from '../../services/projectService';
import * as blogService from '../../services/blogService';
import { 
  FiEdit, 
  FiPlus,
  FiFolder,
  FiBook,
  FiFileText,
  FiGithub,
  FiEye,
  FiEyeOff,
  FiUser,
  FiCalendar,
  FiBarChart2,
  FiActivity,
  FiPlusCircle,
  FiSettings,
  FiRefreshCw,
  FiTrendingUp,
  FiAlertCircle
} from 'react-icons/fi';
import AdminLayout from '../../components/Admin/AdminLayout';
import { toast } from 'react-toastify';

const Container = styled.div`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 992px) {
    padding: 1rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const Card = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows.small};
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.shadows.medium};
  }
`;

const StatsCard = styled(Card)`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StatIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background: ${({ theme, $color }) => theme.colors[$color] + '15'};
  color: ${({ theme, $color }) => theme.colors[$color]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
`;

const StatContent = styled.div`
  flex: 1;
`;

const StatValue = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 0.25rem;
`;

const StatLabel = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
`;

const ViewAllLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const ContentCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ContentTitle = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
`;

const ContentStatus = styled.span`
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  background: ${({ theme, $published }) => 
    $published ? theme.colors.success + '20' : theme.colors.warning + '20'};
  color: ${({ theme, $published }) => 
    $published ? theme.colors.success : theme.colors.warning};
`;

const ContentMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.textSecondary};

  span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const ContentActions = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: auto;
`;

const ActionButton = styled(Link)`
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s;

  ${({ $primary, theme }) => $primary ? `
    background: ${theme.colors.primary};
    color: white;

    &:hover {
      background: ${theme.colors.primary}dd;
    }
  ` : `
    background: ${theme.colors.surface};
    color: ${theme.colors.text};
    border: 1px solid ${theme.colors.border};

    &:hover {
      background: ${theme.colors.background};
    }
  `}
`;

const QuickActions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const QuickActionButton = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.surface};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  transition: all 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.shadows.medium};
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    font-size: 1.25rem;
  }
`;

const StatsGrid = styled(Grid)`
  margin-top: 1rem;
`;

const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.background};
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    transition: transform 0.3s;
  }

  &:disabled svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const WelcomeText = styled.div`
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.text};
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.textSecondary};
    margin: 0.5rem 0 0 0;
  }
`;

const LoadingOverlay = styled.div`
  position: relative;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background}80;
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid ${({ theme }) => theme.colors.border};
  border-top-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.div`
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.error}15;
  color: ${({ theme }) => theme.colors.error};
  border: 1px solid ${({ theme }) => theme.colors.error}30;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    font-size: 1.25rem;
  }
`;

const RetryButton = styled.button`
  margin-left: auto;
  padding: 0.5rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.error};
  border-radius: 6px;
  background: none;
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.error}15;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${({ theme }) => theme.colors.textSecondary};

  svg {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h3 {
    margin: 0 0 0.5rem 0;
    color: ${({ theme }) => theme.colors.text};
  }

  p {
    margin: 0;
  }
`;

const Dashboard = () => {
  const [stats, setStats] = useState({
    totalPosts: 0,
    publishedPosts: 0,
    totalProjects: 0,
    publishedProjects: 0,
    totalViews: 0,
    lastUpdated: null
  });
  const [recentPosts, setRecentPosts] = useState([]);
  const [recentProjects, setRecentProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const fetchDashboardData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Fetch blog posts
      const [publishedPosts, draftPosts] = await Promise.all([
        blogService.getPosts({ status: 'published', limit: 10 }),
        blogService.getPosts({ status: 'draft', limit: 10 })
      ]);
      
      const allPosts = [...(publishedPosts.posts || []), ...(draftPosts.posts || [])];
      setRecentPosts(allPosts.slice(0, 3));
      
      // Fetch projects
      const projects = await projectService.getAllProjects();
      const publishedProjects = projects.filter(project => project.published);
      setRecentProjects(projects.slice(0, 3));
      
      // Calculate total views (safely handle undefined views)
      const totalViews = (publishedPosts.posts || [])
        .reduce((sum, post) => sum + (post.views || 0), 0);
      
      // Update stats
      setStats({
        totalPosts: allPosts.length,
        publishedPosts: (publishedPosts.posts || []).length,
        totalProjects: projects.length,
        publishedProjects: publishedProjects.length,
        totalViews,
        lastUpdated: new Date()
      });
    } catch (error) {
      console.error('Dashboard data fetch error:', error);
      setError(error.message || 'Failed to fetch dashboard data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatTime = (date) => {
    return new Date(date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const renderContent = () => {
    if (loading && !stats.lastUpdated) {
      return (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      );
    }

    if (error) {
      return (
        <ErrorMessage>
          <FiAlertCircle />
          {error}
          <RetryButton onClick={fetchDashboardData}>
            Retry
          </RetryButton>
        </ErrorMessage>
      );
    }

    return (
      <>
        <StatsGrid>
          <StatsCard>
            <StatIcon $color="primary">
              <FiFileText />
            </StatIcon>
            <StatContent>
              <StatValue>{stats.totalPosts}</StatValue>
              <StatLabel>Total Posts</StatLabel>
            </StatContent>
          </StatsCard>
          
          <StatsCard>
            <StatIcon $color="success">
              <FiEye />
            </StatIcon>
            <StatContent>
              <StatValue>{stats.publishedPosts}</StatValue>
              <StatLabel>Published Posts</StatLabel>
            </StatContent>
          </StatsCard>
          
          <StatsCard>
            <StatIcon $color="info">
              <FiFolder />
            </StatIcon>
            <StatContent>
              <StatValue>{stats.totalProjects}</StatValue>
              <StatLabel>Total Projects</StatLabel>
            </StatContent>
          </StatsCard>
          
          <StatsCard>
            <StatIcon $color="warning">
              <FiBarChart2 />
            </StatIcon>
            <StatContent>
              <StatValue>{stats.publishedProjects}</StatValue>
              <StatLabel>Active Projects</StatLabel>
            </StatContent>
          </StatsCard>
        </StatsGrid>

        <Section>
          <SectionHeader>
            <SectionTitle>Recent Blog Posts</SectionTitle>
            <ViewAllLink to="/admin/blog">
              View All Posts <FiActivity />
            </ViewAllLink>
          </SectionHeader>
          
          <ContentGrid>
            {recentPosts.length > 0 ? (
              recentPosts.map(post => (
                <ContentCard key={post.id}>
                  <ContentHeader>
                    <ContentTitle>{post.title}</ContentTitle>
                    <ContentStatus $published={post.published}>
                      {post.published ? 'Published' : 'Draft'}
                    </ContentStatus>
                  </ContentHeader>
                  
                  <ContentMeta>
                    <span><FiCalendar /> {formatDate(post.createdAt)}</span>
                    <span><FiUser /> {post.author}</span>
                  </ContentMeta>
                  
                  <ContentActions>
                    <ActionButton to={`/admin/blog/edit/${post.id}`} $primary>
                      <FiEdit /> Edit Post
                    </ActionButton>
                    <ActionButton to={`/blog/${post.slug}`} target="_blank">
                      <FiEye /> Preview
                    </ActionButton>
                  </ContentActions>
                </ContentCard>
              ))
            ) : (
              <EmptyState>
                <FiFileText />
                <h3>No Posts Yet</h3>
                <p>Create your first blog post to get started</p>
                <ActionButton to="/admin/blog/new" $primary style={{ marginTop: '1rem', display: 'inline-flex' }}>
                  <FiPlusCircle /> Create Post
                </ActionButton>
              </EmptyState>
            )}
          </ContentGrid>
        </Section>

        <Section>
          <SectionHeader>
            <SectionTitle>Recent Projects</SectionTitle>
            <ViewAllLink to="/admin/projects">
              View All Projects <FiActivity />
            </ViewAllLink>
          </SectionHeader>
          
          <ContentGrid>
            {recentProjects.length > 0 ? (
              recentProjects.map(project => (
                <ContentCard key={project.id}>
                  <ContentHeader>
                    <ContentTitle>{project.title}</ContentTitle>
                    <ContentStatus $published={project.published}>
                      {project.published ? 'Live' : 'Draft'}
                    </ContentStatus>
                  </ContentHeader>
                  
                  <ContentMeta>
                    <span><FiFolder /> {project.category}</span>
                    {project.githubUrl && (
                      <span><FiGithub /> GitHub</span>
                    )}
                  </ContentMeta>
                  
                  <ContentActions>
                    <ActionButton to={`/admin/projects/edit/${project.id}`} $primary>
                      <FiEdit /> Edit Project
                    </ActionButton>
                    {project.demoUrl && (
                      <ActionButton href={project.demoUrl} target="_blank">
                        <FiEye /> View Demo
                      </ActionButton>
                    )}
                  </ContentActions>
                </ContentCard>
              ))
            ) : (
              <EmptyState>
                <FiFolder />
                <h3>No Projects Yet</h3>
                <p>Create your first project to get started</p>
                <ActionButton to="/admin/projects/new" $primary style={{ marginTop: '1rem', display: 'inline-flex' }}>
                  <FiPlusCircle /> Create Project
                </ActionButton>
              </EmptyState>
            )}
          </ContentGrid>
        </Section>
      </>
    );
  };

  return (
    <AdminLayout>
      <Container>
        <DashboardHeader>
          <WelcomeText>
            <h1>Welcome back, {currentUser?.displayName || 'Admin'}</h1>
            <p>Here's what's happening with your content</p>
          </WelcomeText>
          <RefreshButton onClick={fetchDashboardData} disabled={loading}>
            <FiRefreshCw /> {loading ? 'Refreshing...' : 'Refresh'}
          </RefreshButton>
        </DashboardHeader>

        <QuickActions>
          <QuickActionButton to="/admin/blog/new">
            <FiPlusCircle /> New Blog Post
          </QuickActionButton>
          <QuickActionButton to="/admin/projects/new">
            <FiPlusCircle /> New Project
          </QuickActionButton>
          <QuickActionButton to="/admin/settings">
            <FiSettings /> Settings
          </QuickActionButton>
          <QuickActionButton to="/admin/analytics">
            <FiTrendingUp /> Analytics
          </QuickActionButton>
        </QuickActions>

        {renderContent()}
        
        {stats.lastUpdated && (
          <small style={{ color: 'var(--text-secondary)', display: 'block', textAlign: 'center', marginTop: '2rem' }}>
            Last updated: {formatDate(stats.lastUpdated)} at {formatTime(stats.lastUpdated)}
          </small>
        )}
      </Container>
    </AdminLayout>
  );
};

export default Dashboard;
