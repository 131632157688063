import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { FiMail } from 'react-icons/fi';
import { toast } from 'react-toastify';
import Navbar from '../../components/Navbar/Navbar';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 80px;
`;

const PageContainer = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.background};
`;

const ResetCard = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  padding: 2rem;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: ${({ theme }) => theme.shadows.large};
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  margin-bottom: 1rem;
  font-size: ${({ theme }) => theme.typography.fontSizes['2xl']};
`;

const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  text-align: center;
  margin-bottom: 2rem;
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes.base};

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => `${theme.colors.primary}20`};
  }
`;

const Button = styled.button`
  padding: 0.75rem;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: ${({ theme }) => theme.typography.fontSizes.base};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.default};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    transform: translateY(-2px);
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
`;

const Message = styled.div`
  text-align: center;
  padding: 0.75rem;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-top: 1rem;
  
  ${({ variant, theme }) => {
    switch (variant) {
      case 'error':
        return `
          color: ${theme.colors.error};
          background: ${theme.colors.error}10;
        `;
      case 'success':
        return `
          color: ${theme.colors.success};
          background: ${theme.colors.success}10;
        `;
      default:
        return `
          color: ${theme.colors.textSecondary};
          background: transparent;
        `;
    }
  }}
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  transition: opacity ${({ theme }) => theme.transitions.default};

  &:hover {
    opacity: 0.8;
  }
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }

    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    setLoading(true);
    setMessage({ text: '', type: '' });

    try {
      await resetPassword(email);
      
      // If we get here, the email was sent successfully
      setMessage({
        text: 'Password reset link has been sent to your email address.',
        type: 'success'
      });
      toast.success('Password reset email sent successfully! Please check your inbox.');
      
      // Give user time to see the success message before redirecting
      setTimeout(() => {
        navigate('/admin/login');
      }, 5000);
    } catch (err) {
      console.error('Reset password error:', err);
      let errorMessage;
      
      // Firebase error codes
      switch(err.code) {
        case 'auth/user-not-found':
          errorMessage = 'No account exists with this email address.';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Please enter a valid email address.';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Too many attempts. Please try again later.';
          break;
        case 'auth/network-request-failed':
          errorMessage = 'Network error. Please check your internet connection.';
          break;
        case 'auth/missing-email':
          errorMessage = 'Please enter an email address.';
          break;
        default:
          errorMessage = 'An error occurred. Please try again.';
          console.error('Detailed error:', err);
      }
      
      setMessage({
        text: errorMessage,
        type: 'error'
      });
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <Navbar />
      <Container>
        <ResetCard>
          <Title>Reset Password</Title>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="email"
                placeholder="Enter your email"
                disabled={loading}
              />
            </FormGroup>
            <Button type="submit" disabled={loading}>
              <FiMail /> {loading ? 'Sending...' : 'Send Reset Link'}
            </Button>
          </Form>
          
          {message.text && (
            <Message variant={message.type}>
              {message.text}
            </Message>
          )}
          
          <LinkContainer>
            <StyledLink to="/admin/login">Back to Login</StyledLink>
          </LinkContainer>
        </ResetCard>
      </Container>
    </PageContainer>
  );
};

export default ForgotPassword;
