import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import MDEditor from '@uiw/react-md-editor';
import { FiSave, FiX, FiUpload, FiEye } from 'react-icons/fi';
import { createPost, updatePost, getPost, uploadImage } from '../../services/blogService';
import AdminLayout from '../../components/Admin/AdminLayout';
import { toast } from 'react-toastify';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes['2xl']};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background: ${({ theme, $primary, $danger }) => 
    $primary ? theme.colors.primary : 
    $danger ? theme.colors.error :
    theme.colors.surface};
  color: ${({ theme, $primary, $danger }) => 
    $primary || $danger ? 'white' : theme.colors.text};

  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  font-weight: 500;
`;

const Input = styled.input`
  padding: 0.75rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.text};
  font-size: 1rem;
  width: 100%;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}20;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const Tag = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  background: ${({ theme }) => theme.colors.surfaceHover};
  color: ${({ theme }) => theme.colors.text};
  border-radius: 999px;
  font-size: 0.875rem;

  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    
    &:hover {
      color: ${({ theme }) => theme.colors.error};
    }
  }
`;

const ImagePreview = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 0.5rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: ${({ theme }) => theme.colors.surface};
    border: none;
    border-radius: 999px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.error};
    
    &:hover {
      background: ${({ theme }) => theme.colors.error};
      color: white;
    }
  }
`;

const EditorContainer = styled.div`
  .w-md-editor {
    background: ${({ theme }) => theme.colors.surface};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 8px;
  }
`;

const BlogEditor = () => {
  const navigate = useNavigate();
  const { postId } = useParams();
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState({
    title: '',
    content: '',
    excerpt: '',
    tags: [],
    status: 'draft',
    featuredImage: ''
  });
  const [tagInput, setTagInput] = useState('');

  useEffect(() => {
    if (postId) {
      loadPost();
    }
  }, [postId]);

  const loadPost = async () => {
    try {
      setLoading(true);
      const loadedPost = await getPost(postId);
      setPost(loadedPost);
    } catch (error) {
      toast.error('Error loading post: ' + error.message);
      navigate('/admin/blog');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleContentChange = (value) => {
    setPost(prev => ({
      ...prev,
      content: value || ''
    }));
  };

  const handleTagInputKeyDown = (e) => {
    if (e.key === 'Enter' && tagInput.trim()) {
      e.preventDefault();
      if (!post.tags.includes(tagInput.trim())) {
        setPost(prev => ({
          ...prev,
          tags: [...prev.tags, tagInput.trim()]
        }));
      }
      setTagInput('');
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setPost(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToDelete)
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setLoading(true);
        const imageUrl = await uploadImage(file);
        setPost(prev => ({
          ...prev,
          featuredImage: imageUrl
        }));
        toast.success('Image uploaded successfully!');
      } catch (error) {
        toast.error('Error uploading image: ' + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (e, status = post.status) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      const postData = { ...post, status };
      
      if (postId) {
        await updatePost(postId, postData);
        toast.success('Post updated successfully!');
      } else {
        await createPost(postData);
        toast.success('Post created successfully!');
      }
      
      navigate('/admin/blog', { replace: true });
    } catch (error) {
      toast.error(`Error ${postId ? 'updating' : 'creating'} post: ${error.message}`);
      setLoading(false);
    }
  };

  if (loading && !post.id) {
    return (
      <AdminLayout>
        <Container>
          <div>Loading...</div>
        </Container>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <Container>
        <Header>
          <Title>{postId ? 'Edit Post' : 'New Post'}</Title>
          <ActionButtons>
            <Button 
              type="button" 
              onClick={() => navigate('/admin/blog', { replace: true })}
              $danger
            >
              <FiX /> Cancel
            </Button>
            <Button
              type="button"
              onClick={(e) => handleSubmit(e, 'draft')}
              disabled={loading}
            >
              <FiSave /> Save Draft
            </Button>
            <Button
              type="button"
              onClick={(e) => handleSubmit(e, 'published')}
              disabled={loading}
              $primary
            >
              <FiEye /> {post.status === 'published' ? 'Update' : 'Publish'}
            </Button>
          </ActionButtons>
        </Header>

        <Form onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Label>Title</Label>
            <Input
              type="text"
              name="title"
              value={post.title}
              onChange={handleInputChange}
              placeholder="Enter post title"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>Excerpt</Label>
            <Input
              as="textarea"
              name="excerpt"
              value={post.excerpt}
              onChange={handleInputChange}
              placeholder="Enter a brief excerpt"
              style={{ minHeight: '80px', resize: 'vertical' }}
            />
          </FormGroup>

          <FormGroup>
            <Label>Tags</Label>
            <Input
              type="text"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              onKeyDown={handleTagInputKeyDown}
              placeholder="Type a tag and press Enter"
            />
            <TagsContainer>
              {post.tags.map(tag => (
                <Tag key={tag}>
                  {tag}
                  <button type="button" onClick={() => handleTagDelete(tag)}>
                    <FiX size={14} />
                  </button>
                </Tag>
              ))}
            </TagsContainer>
          </FormGroup>

          <FormGroup>
            <Label>Featured Image</Label>
            <Button
              type="button"
              onClick={() => document.getElementById('imageInput').click()}
              style={{ width: 'fit-content' }}
            >
              <FiUpload /> Upload Image
            </Button>
            <input
              id="imageInput"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            {post.featuredImage && (
              <ImagePreview>
                <img src={post.featuredImage} alt="Featured" />
                <button
                  type="button"
                  onClick={() => setPost(prev => ({ ...prev, featuredImage: '' }))}
                >
                  <FiX size={14} />
                </button>
              </ImagePreview>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Content</Label>
            <EditorContainer>
              <MDEditor
                value={post.content}
                onChange={handleContentChange}
                preview="edit"
                height={500}
              />
            </EditorContainer>
          </FormGroup>
        </Form>
      </Container>
    </AdminLayout>
  );
};

export default BlogEditor;
