import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { projectService } from '../../services/projectService';
import { FiSave, FiX, FiUpload } from 'react-icons/fi';
import LoadingSpinner, { ButtonSpinner } from '../../components/LoadingSpinner';
import MDEditor from '@uiw/react-md-editor';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import AdminLayout from '../../components/Admin/AdminLayout';

const Container = styled.div`
  padding: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes['2xl']};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background: ${({ variant, theme }) =>
    variant === 'secondary' ? 'transparent' : theme.colors.primary};
  color: ${({ variant, theme }) =>
    variant === 'secondary' ? theme.colors.text : 'white'};
  border: ${({ variant, theme }) =>
    variant === 'secondary' ? `1px solid ${theme.colors.border}` : 'none'};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    opacity: 0.9;
    transform: translateY(-1px);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const Select = styled.select`
  padding: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const TextArea = styled.textarea`
  padding: 0.8rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  font-size: 1rem;
  min-height: 100px;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const ImagePreview = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 8px;
  background-image: url(${({ $image }) => $image || '/default-project.jpg'});
  background-size: cover;
  background-position: center;
  margin-bottom: 1rem;
  border: 2px dashed ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
    opacity: 0.9;
  }
`;

const ImageUploadIcon = styled(FiUpload)`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const Tag = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.text};
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.border};

  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.error};
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    
    &:hover {
      opacity: 0.8;
    }
  }
`;

const Toggle = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: ${({ theme }) => theme.colors.success};
  }

  &:checked + span:before {
    transform: translateX(26px);
  }
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const projectCategories = [
  'Machine Learning',
  'Web Development',
  'Mobile Development',
  'Data Science',
  'DevOps',
  'Cloud Computing',
  'Artificial Intelligence',
  'Cybersecurity',
  'Networking',
  'Database Administration',
  'Other'
];

const ProjectEditor = () => {
  const { id: projectId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(projectId ? true : false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: projectCategories[0],
    technologies: [],
    githubUrl: '',
    demoUrl: '',
    content: '',
    published: false,
    imageUrl: '',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    author: currentUser?.email || '',
  });

  useEffect(() => {
    const fetchProject = async () => {
      if (!projectId) return;
      
      try {
        const project = await projectService.getProjectById(projectId);
        setFormData(project);
      } catch (error) {
        toast.error('Error fetching project: ' + error.message);
        navigate('/admin/projects');
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId, navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleTechnologyAdd = (e) => {
    if (e.key === 'Enter' && e.target.value.trim()) {
      e.preventDefault();
      const newTech = e.target.value.trim();
      if (!formData.technologies.includes(newTech)) {
        setFormData(prev => ({
          ...prev,
          technologies: [...prev.technologies, newTech]
        }));
      }
      e.target.value = '';
    }
  };

  const handleTechnologyRemove = (techToRemove) => {
    setFormData(prev => ({
      ...prev,
      technologies: prev.technologies.filter(tech => tech !== techToRemove)
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const imageUrl = await projectService.uploadImage(file);
      setFormData(prev => ({
        ...prev,
        imageUrl
      }));
      toast.success('Image uploaded successfully');
    } catch (error) {
      toast.error('Error uploading image: ' + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setSaving(true);
      const updatedData = {
        ...formData,
        updatedAt: new Date().toISOString()
      };
      
      if (projectId) {
        await projectService.updateProject(projectId, updatedData);
        toast.success('Project updated successfully!');
      } else {
        await projectService.createProject(updatedData);
        toast.success('Project created successfully!');
      }
      
      navigate('/admin/projects');
    } catch (error) {
      toast.error(`Error ${projectId ? 'updating' : 'creating'} project: ${error.message}`);
      setError(error.message);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <Container>
          <LoadingSpinner />
        </Container>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <Container>
        <Header>
          <Title>{projectId ? 'Edit Project' : 'New Project'}</Title>
          <ButtonGroup>
            <Button 
              type="button" 
              variant="secondary"
              onClick={() => navigate('/admin/projects')}
            >
              <FiX /> Cancel
            </Button>
            <Button 
              onClick={handleSubmit}
              disabled={saving}
            >
              {saving ? <ButtonSpinner /> : <FiSave />}
              {saving ? 'Saving...' : 'Save'}
            </Button>
          </ButtonGroup>
        </Header>

        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Project Image</Label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              id="imageUpload"
            />
            <label htmlFor="imageUpload">
              <ImagePreview $image={formData.imageUrl}>
                {!formData.imageUrl && <ImageUploadIcon />}
              </ImagePreview>
            </label>
          </FormGroup>

          <FormGroup>
            <Label>Title *</Label>
            <Input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Enter project title"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>Description *</Label>
            <TextArea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Brief description of your project"
              required
            />
          </FormGroup>

          <FormGroup>
            <Label>Category</Label>
            <Select
              name="category"
              value={formData.category}
              onChange={handleChange}
            >
              {projectCategories.map(category => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>Technologies</Label>
            <Input
              type="text"
              placeholder="Type and press Enter to add technologies"
              onKeyDown={handleTechnologyAdd}
            />
            <TagsContainer>
              {formData.technologies.map(tech => (
                <Tag key={tech}>
                  {tech}
                  <button type="button" onClick={() => handleTechnologyRemove(tech)}>×</button>
                </Tag>
              ))}
            </TagsContainer>
          </FormGroup>

          <FormGroup>
            <Label>GitHub URL</Label>
            <Input
              type="url"
              name="githubUrl"
              value={formData.githubUrl}
              onChange={handleChange}
              placeholder="https://github.com/username/project"
            />
          </FormGroup>

          <FormGroup>
            <Label>Demo URL</Label>
            <Input
              type="url"
              name="demoUrl"
              value={formData.demoUrl}
              onChange={handleChange}
              placeholder="https://your-project-demo.com"
            />
          </FormGroup>

          <FormGroup>
            <Label>Content</Label>
            <MDEditor
              value={formData.content}
              onChange={(value) => setFormData(prev => ({ ...prev, content: value }))}
              preview="edit"
              height={400}
            />
          </FormGroup>

          <FormGroup>
            <Label style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <input
                type="checkbox"
                name="published"
                checked={formData.published}
                onChange={handleChange}
              />
              Published
            </Label>
          </FormGroup>

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>
      </Container>
    </AdminLayout>
  );
};

export default ProjectEditor;
