import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';
import { FaChartBar, FaEdit, FaFolder, FaSignOutAlt, FaHome } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import { toast } from 'react-toastify';
import logo from '../../assets/logo.svg';

const AdminNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { isDarkMode, toggleTheme } = useTheme();
  const { logout } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const navItems = [
    { path: '/admin', label: 'Dashboard', icon: <FaChartBar /> },
    { path: '/admin/blog', label: 'Blogs', icon: <FaEdit /> },
    { path: '/admin/projects', label: 'Projects', icon: <FaFolder /> },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      toast.success('Logged out successfully');
      navigate('/admin/login');
    } catch (error) {
      toast.error('Failed to log out');
      console.error('Logout error:', error);
    }
  };

  return (
    <>
      <NavContainer scrolled={scrolled}>
        <NavContent>
          <LogoLink to="/admin">
            <LogoContainer>
              <StyledLogo src={logo} alt="Logo" $isDark={isDarkMode} />
              <Logo>
                <LogoName>Admin</LogoName>
                <LogoSurname>Panel</LogoSurname>
              </Logo>
            </LogoContainer>
          </LogoLink>

          <DesktopNav>
            {navItems.map((item) => (
              <NavLink
                key={item.path}
                to={item.path}
                $isActive={location.pathname === item.path}
              >
                {item.icon}
                <span>{item.label}</span>
              </NavLink>
            ))}
            <ThemeToggle onClick={toggleTheme}>
              {isDarkMode ? '🌙' : '☀️'}
            </ThemeToggle>
            <HomeButton to="/">
              <FaHome />
            </HomeButton>
            <LogoutButton onClick={handleLogout}>
              <FaSignOutAlt />
            </LogoutButton>
          </DesktopNav>

          <MobileMenuButton onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </MobileMenuButton>
        </NavContent>
      </NavContainer>

      <AnimatePresence>
        {isOpen && (
          <>
            <MobileMenuOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => setIsOpen(false)}
            />
            <MobileNav
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'tween', duration: 0.3 }}
            >
              {navItems.map((item) => (
                <MobileNavLink
                  key={item.path}
                  to={item.path}
                  $isActive={location.pathname === item.path}
                  onClick={() => setIsOpen(false)}
                >
                  {item.icon}
                  <span>{item.label}</span>
                </MobileNavLink>
              ))}
              <MobileThemeToggle onClick={toggleTheme}>
                {isDarkMode ? '🌙 Dark Mode' : '☀️ Light Mode'}
              </MobileThemeToggle>
              <MobileHomeButton to="/">
                <FaHome /> Back to Site
              </MobileHomeButton>
              <MobileLogoutButton onClick={handleLogout}>
                <FaSignOutAlt /> Logout
              </MobileLogoutButton>
            </MobileNav>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

const NavContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: ${({ theme, scrolled }) =>
    scrolled
      ? theme.colors.background
      : 'transparent'};
  backdrop-filter: ${({ scrolled }) =>
    scrolled ? 'blur(10px)' : 'none'};
  transition: all 0.3s ease;
  box-shadow: ${({ scrolled }) =>
    scrolled ? '0 2px 10px rgba(0, 0, 0, 0.1)' : 'none'};
  height: 64px;
`;

const NavContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const LogoLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledLogo = styled.img`
  height: 40px;
  width: auto;
  transition: all 0.3s ease;
  filter: ${({ $isDark }) => $isDark ? 'brightness(0) invert(1)' : 'none'};
  
  @media (max-width: 992px) {
    height: 35px;
  }
`;

const Logo = styled.span`
  font-size: 1.8rem;
  font-weight: 700;
  background: linear-gradient(135deg, ${({ theme }) => theme.colors.primary}, ${({ theme }) => theme.colors.secondary});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const LogoName = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  margin-right: 0.5rem;
`;

const LogoSurname = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
`;

const DesktopNav = styled.div`
  display: none;
  gap: 2rem;
  align-items: center;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primary : theme.colors.text};
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    font-size: 1.2rem;
  }
`;

const ThemeToggle = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text};
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text};
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transform: translateY(-2px);
  }

  svg {
    font-size: 1.2rem;
  }
`;

const HomeButton = styled(Link)`
  ${ActionButton};
  text-decoration: none;
`;

const LogoutButton = styled(ActionButton)``;

const MobileMenuButton = styled(ActionButton)`
  display: flex;
  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMenuOverlay = styled(motion.div)`
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
`;

const MobileNav = styled(motion.nav)`
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  width: 250px;
  background: ${({ theme }) => theme.colors.background};
  padding: 2rem;
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MobileNavLink = styled(NavLink)`
  padding: 1rem;
  border-radius: 8px;
  background: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.surface : 'transparent'};

  &:hover {
    background: ${({ theme }) => theme.colors.surface};
  }
`;

const MobileActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.surface};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const MobileThemeToggle = styled(MobileActionButton)``;
const MobileHomeButton = styled(Link)`
  ${MobileActionButton};
  text-decoration: none;
`;
const MobileLogoutButton = styled(MobileActionButton)``;

export default AdminNavbar;
