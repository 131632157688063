import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { projectService } from '../../services/projectService';
import { FiEdit2, FiTrash2, FiPlus, FiGithub, FiExternalLink, FiTag } from 'react-icons/fi';
import { InlineSpinner } from '../../components/LoadingSpinner';
import AdminLayout from '../../components/Admin/AdminLayout';
import { toast } from 'react-toastify';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 70px);
  position: relative;
  animation: ${fadeIn} 0.3s ease;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.background}60;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  z-index: 1;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.3s ease;
  pointer-events: ${({ $isVisible }) => ($isVisible ? 'auto' : 'none')};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes['2xl']};
  margin: 0;
  font-weight: 600;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
  }

  svg {
    font-size: 1.2rem;
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const ProjectCard = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
`;

const ProjectImage = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${({ $image }) => $image || '/default-project.jpg'});
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  position: relative;
`;

const ProjectStatus = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  background: ${({ $published, theme }) => 
    $published ? theme.colors.success + '20' : theme.colors.warning + '20'};
  color: ${({ $published, theme }) => 
    $published ? theme.colors.success : theme.colors.warning};
  backdrop-filter: blur(4px);
`;

const ProjectContent = styled.div`
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ProjectMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 0.875rem;

  svg {
    font-size: 1rem;
  }
`;

const ProjectLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: auto;
  padding-top: 1rem;
`;

const ProjectLink = styled.a`
  color: ${({ theme }) => theme.colors.textSecondary};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.875rem;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ProjectTitle = styled.h3`
  margin: 0 0 0.5rem 0;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.typography.fontSizes.lg};
`;

const ProjectDescription = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  margin: 0 0 1rem 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ProjectActions = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.textSecondary};
  transition: all 0.2s ease;

  &:hover {
    color: ${({ theme, $delete }) => $delete ? theme.colors.error : theme.colors.primary};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();

  const fetchProjects = useCallback(async () => {
    try {
      setLoading(true);
      const fetchedProjects = await projectService.getAllProjects();
      setProjects(fetchedProjects);
    } catch (error) {
      toast.error('Error fetching projects: ' + error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const handleDelete = async (projectId) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      try {
        setLoading(true);
        await projectService.deleteProject(projectId);
        toast.success('Project deleted successfully');
        setProjects(projects.filter(project => project.id !== projectId));
      } catch (error) {
        toast.error('Error deleting project: ' + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const filteredProjects = projects.filter(project => {
    if (filter === 'all') return true;
    if (filter === 'published') return project.published;
    if (filter === 'draft') return !project.published;
    return true;
  });

  return (
    <AdminLayout>
      <Container>
        <LoadingOverlay $isVisible={loading}>
          <InlineSpinner size={40} />
        </LoadingOverlay>
        
        <Header>
          <div>
            <Title>Projects</Title>
            <ProjectMeta>
              {projects.length} total projects • {projects.filter(p => p.published).length} published
            </ProjectMeta>
          </div>
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <select 
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              style={{ marginRight: '1rem' }}
            >
              <option value="all">All Projects</option>
              <option value="published">Published</option>
              <option value="draft">Drafts</option>
            </select>
            <AddButton onClick={() => navigate('/admin/projects/new')}>
              <FiPlus /> New Project
            </AddButton>
          </div>
        </Header>

        <ProjectGrid>
          {filteredProjects.map(project => (
            <ProjectCard key={project.id}>
              <ProjectImage $image={project.imageUrl}>
                <ProjectStatus $published={project.published}>
                  {project.published ? 'Published' : 'Draft'}
                </ProjectStatus>
              </ProjectImage>
              <ProjectContent>
                <ProjectMeta>
                  <FiTag /> {project.category}
                </ProjectMeta>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectDescription>{project.description}</ProjectDescription>
                
                <ProjectLinks>
                  {project.githubUrl && (
                    <ProjectLink href={project.githubUrl} target="_blank" rel="noopener noreferrer">
                      <FiGithub /> GitHub
                    </ProjectLink>
                  )}
                  {project.demoUrl && (
                    <ProjectLink href={project.demoUrl} target="_blank" rel="noopener noreferrer">
                      <FiExternalLink /> Demo
                    </ProjectLink>
                  )}
                </ProjectLinks>

                <ProjectActions>
                  <ActionButton
                    onClick={() => navigate(`/admin/projects/edit/${project.id}`)}
                    title="Edit project"
                  >
                    <FiEdit2 size={20} />
                  </ActionButton>
                  <ActionButton
                    onClick={() => handleDelete(project.id)}
                    title="Delete project"
                    $delete
                  >
                    <FiTrash2 size={20} />
                  </ActionButton>
                </ProjectActions>
              </ProjectContent>
            </ProjectCard>
          ))}
        </ProjectGrid>
      </Container>
    </AdminLayout>
  );
};

export default ProjectList;
