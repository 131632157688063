import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { db } from '../../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { formatDate } from '../../utils/dateUtils';
import { useAuth } from '../../contexts/AuthContext';
import { FaCalendar, FaClock, FaTag, FaUser, FaHome } from 'react-icons/fa';

const BlogPostContainer = styled.article`
  max-width: 800px;
  margin: 100px auto 40px;
  padding: 0 20px;
`;

const AdminPanel = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  gap: 10px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const AdminButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryDark};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  svg {
    font-size: 16px;
  }
`;

const BlogHeader = styled.header`
  margin-bottom: 3rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 1.5rem;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -0.5px;
`;

const CoverImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
`;

const MetaData = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

const MetaItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 1rem;

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Category = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: ${({ theme }) => theme.colors.primary}15;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`;

const Content = styled.div`
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.8;
  font-size: 1.1rem;
  
  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.text};
    margin: 2.5rem 0 1.5rem;
    line-height: 1.3;
    font-weight: 600;
  }

  h1 { font-size: 2.5rem; }
  h2 { font-size: 2rem; }
  h3 { font-size: 1.75rem; }
  h4 { font-size: 1.5rem; }
  h5 { font-size: 1.25rem; }
  h6 { font-size: 1.1rem; }

  p {
    margin-bottom: 1.5rem;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: all 0.2s;

    &:hover {
      border-bottom-color: ${({ theme }) => theme.colors.primary};
    }
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    margin: 2rem auto;
    display: block;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  code {
    background: ${({ theme }) => theme.colors.background};
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.9em;
  }

  pre {
    background: ${({ theme }) => theme.colors.background};
    padding: 1.5rem;
    border-radius: 12px;
    overflow-x: auto;
    margin: 2rem 0;
    border: 1px solid ${({ theme }) => theme.colors.border};

    code {
      background: none;
      padding: 0;
      font-size: 0.9em;
    }
  }

  blockquote {
    border-left: 4px solid ${({ theme }) => theme.colors.primary};
    padding: 1rem 1.5rem;
    margin: 2rem 0;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 0 12px 12px 0;
    font-style: italic;
    color: ${({ theme }) => theme.colors.textSecondary};

    p {
      margin: 0;
    }
  }

  ul, ol {
    margin: 1.5rem 0;
    padding-left: 2rem;

    li {
      margin-bottom: 0.75rem;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0;
    font-size: 0.9em;
  }

  th, td {
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 0.75rem;
    text-align: left;
  }

  th {
    background: ${({ theme }) => theme.colors.background};
    font-weight: 600;
  }

  tr:nth-child(even) {
    background: ${({ theme }) => theme.colors.background};
  }
`;

const AuthorImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 40px;
    height: 40px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const AuthorSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    text-align: center;
    padding: 0.75rem;
    gap: 0.75rem;
  }
`;

const AuthorInfo = styled.div`
  flex: 1;
  min-width: 0;
`;

const AuthorName = styled.h3`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 0.25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1rem;
  }
`;

const AuthorBio = styled.p`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 1.5;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 0.85rem;
  }
`;

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const docRef = doc(db, 'blog_posts', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPost({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('Post not found');
        }
      } catch (err) {
        console.error('Error fetching post:', err);
        setError('Failed to load the post');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return (
      <BlogPostContainer>
        <div>Loading...</div>
      </BlogPostContainer>
    );
  }

  if (error) {
    return (
      <BlogPostContainer>
        <div>{error}</div>
      </BlogPostContainer>
    );
  }

  if (!post) {
    return (
      <BlogPostContainer>
        <div>Post not found</div>
      </BlogPostContainer>
    );
  }

  return (
    <>
      {currentUser && (
        <AdminPanel>
          <AdminButton onClick={() => navigate('/')}>
            <FaHome />
            Go to Homepage
          </AdminButton>
        </AdminPanel>
      )}
      <BlogPostContainer>
        <BlogHeader>
          {post.coverImage && (
            <CoverImage src={post.coverImage} alt={post.title} />
          )}
          
          <Category>
            <FaTag />
            {post.category}
          </Category>
          
          <Title>{post.title}</Title>
          
          <MetaData>
            <MetaItem>
              <FaCalendar />
              {formatDate(post.createdAt?.toDate())}
            </MetaItem>
            <MetaItem>
              <FaUser />
              {post.author}
            </MetaItem>
            <MetaItem>
              <FaClock />
              {post.readTime}
            </MetaItem>
          </MetaData>
        </BlogHeader>

        <Content>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {post.content}
          </ReactMarkdown>
        </Content>

        {post.authorImage && (
          <AuthorSection>
            <AuthorImage>
              <img src={post.authorImage} alt={post.author} />
            </AuthorImage>
            <AuthorInfo>
              <AuthorName>{post.author}</AuthorName>
              <AuthorBio>{post.authorBio}</AuthorBio>
            </AuthorInfo>
          </AuthorSection>
        )}
      </BlogPostContainer>
    </>
  );
};

export default BlogPost;
