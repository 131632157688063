import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../config/firebase';
import { 
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  onAuthStateChanged
} from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    // Set up authentication observer
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  async function login(email, password) {
    try {
      setError('');
      const result = await signInWithEmailAndPassword(auth, email, password);
      return result;
    } catch (err) {
      console.error('Login error:', err);
      setError(err.message || 'Failed to log in');
      throw err;
    }
  }

  async function logout() {
    try {
      setError('');
      await firebaseSignOut(auth);
      // Clear any auth-related state here
      setCurrentUser(null);
    } catch (err) {
      console.error('Logout error:', err);
      setError(err.message || 'Failed to log out');
      throw err;
    }
  }

  async function resetPassword(email) {
    try {
      setError('');
      
      // Send reset email directly and let Firebase handle the validation
      await sendPasswordResetEmail(auth, email, {
        url: window.location.origin + '/admin/login',
        handleCodeInApp: false
      });
      
    } catch (err) {
      console.error('Password reset error:', err);
      // Rethrow the error to be handled by the component
      throw err;
    }
  }

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    error
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
