import { storage } from '../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

export const projectService = {
  // Upload project image
  async uploadImage(file) {
    try {
      const storageRef = ref(storage, `project-images/${Date.now()}-${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(snapshot.ref);
      return downloadUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  },

  // Create a new project
  async createProject(projectData) {
    try {
      const response = await fetch(`${API_URL}/projects`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectData),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      console.error('Error creating project:', error);
      throw error;
    }
  },

  // Get all projects
  async getAllProjects() {
    try {
      const response = await fetch(`${API_URL}/projects`);
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      console.error('Error getting projects:', error);
      throw error;
    }
  },

  // Get featured projects
  async getFeaturedProjects() {
    try {
      const response = await fetch(`${API_URL}/projects/featured`);
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      console.error('Error getting featured projects:', error);
      throw error;
    }
  },

  // Get a single project by ID
  async getProjectById(id) {
    try {
      const response = await fetch(`${API_URL}/projects/${id}`);
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      console.error('Error getting project:', error);
      throw error;
    }
  },

  // Get projects by category
  async getProjectsByCategory(category) {
    try {
      const response = await fetch(`${API_URL}/projects/category/${category}`);
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      console.error('Error getting projects by category:', error);
      throw error;
    }
  },

  // Update a project
  async updateProject(id, projectData) {
    try {
      const response = await fetch(`${API_URL}/projects/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectData),
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      return data;
    } catch (error) {
      console.error('Error updating project:', error);
      throw error;
    }
  },

  // Delete a project
  async deleteProject(id) {
    try {
      const response = await fetch(`${API_URL}/projects/${id}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message);
      return true;
    } catch (error) {
      console.error('Error deleting project:', error);
      throw error;
    }
  }
};