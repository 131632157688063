import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { AuthProvider } from './contexts/AuthContext';
import { useTheme } from './contexts/ThemeContext';
import { getTheme } from './styles/theme';
import GlobalStyle from './styles/GlobalStyle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './components/PrivateRoute';
import styled from 'styled-components';

// Components
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Projects from './pages/Projects/Projects';
import Contact from './pages/Contact/Contact';
import Blog from './pages/Blog/Blog';
import BlogPost from './pages/Blog/BlogPost';
import BlogPage from './pages/Blog/BlogPage';
import Login from './pages/Auth/Login';
import ForgotPassword from './pages/Auth/ForgotPassword';
import Dashboard from './pages/Admin/Dashboard';
import BlogEditor from './pages/Admin/BlogEditor';
import BlogList from './pages/Admin/BlogList';
import ProjectList from './pages/Admin/ProjectList';
import ProjectEditor from './pages/Admin/ProjectEditor';

const MainContent = styled.main`
  padding-top: 80px; /* Height of navbar (64px) + extra space */
  min-height: calc(100vh - 80px); /* Ensure content takes up full viewport height minus navbar */
  width: 100%;
  display: flex;
  flex-direction: column;
`;

// Layout wrapper component
const AppLayout = ({ children }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <>
      {!isAdminRoute && <Navbar />}
      <MainContent>
        {children}
      </MainContent>
      {!isAdminRoute && <Footer />}
    </>
  );
};

function App() {
  const { isDarkMode } = useTheme();
  const theme = getTheme(isDarkMode);

  return (
    <StyledThemeProvider theme={theme}>
      <GlobalStyle />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <AuthProvider>
          <AppLayout>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              <Route path="/blog/page/:page" element={<BlogPage />} />

              {/* Auth Routes */}
              <Route path="/admin/login" element={<Login />} />
              <Route path="/admin/forgot-password" element={<ForgotPassword />} />
              
              {/* Protected Admin Routes */}
              <Route
                path="/admin/*"
                element={
                  <PrivateRoute>
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/blog" element={<BlogList />} />
                      <Route path="/blog/new" element={<BlogEditor />} />
                      <Route path="/blog/edit/:postId" element={<BlogEditor />} />
                      <Route path="/projects" element={<ProjectList />} />
                      <Route path="/projects/new" element={<ProjectEditor />} />
                      <Route path="/projects/edit/:id" element={<ProjectEditor />} />
                    </Routes>
                  </PrivateRoute>
                }
              />
            </Routes>
          </AppLayout>
        </AuthProvider>
      </Router>
    </StyledThemeProvider>
  );
}

export default App;
