import { db, storage } from '../config/firebase';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  serverTimestamp,
  increment
} from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject
} from 'firebase/storage';

const POSTS_COLLECTION = 'blog_posts';
const COMMENTS_COLLECTION = 'blog_comments';

// Post Management
export const createPost = async (postData) => {
  try {
    const postsRef = collection(db, POSTS_COLLECTION);
    const newPost = {
      ...postData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      views: 0,
      status: postData.status || 'draft'
    };
    
    const docRef = await addDoc(postsRef, newPost);
    return { id: docRef.id, ...newPost };
  } catch (error) {
    console.error('Error creating post:', error);
    throw error;
  }
};

export const updatePost = async (postId, postData) => {
  try {
    const postRef = doc(db, POSTS_COLLECTION, postId);
    const updateData = {
      ...postData,
      updatedAt: serverTimestamp()
    };
    await updateDoc(postRef, updateData);
    return { id: postId, ...updateData };
  } catch (error) {
    console.error('Error updating post:', error);
    throw error;
  }
};

export const deletePost = async (postId) => {
  try {
    const postRef = doc(db, POSTS_COLLECTION, postId);
    await deleteDoc(postRef);
    return true;
  } catch (error) {
    console.error('Error deleting post:', error);
    throw error;
  }
};

export const getPost = async (postId) => {
  try {
    const postRef = doc(db, POSTS_COLLECTION, postId);
    const postSnap = await getDoc(postRef);
    
    if (!postSnap.exists()) {
      throw new Error('Post not found');
    }
    
    return { id: postSnap.id, ...postSnap.data() };
  } catch (error) {
    console.error('Error getting post:', error);
    throw error;
  }
};

export const getPosts = async ({ page = 1, limit: pageSize = 10, status = 'published', tag = null } = {}) => {
  try {
    const postsRef = collection(db, POSTS_COLLECTION);
    let q = query(postsRef, orderBy('createdAt', 'desc'));

    if (status) {
      q = query(q, where('status', '==', status));
    }

    if (tag) {
      q = query(q, where('tags', 'array-contains', tag));
    }

    q = query(q, limit(pageSize));

    const querySnapshot = await getDocs(q);
    const posts = [];
    querySnapshot.forEach((doc) => {
      posts.push({ id: doc.id, ...doc.data() });
    });

    return posts;
  } catch (error) {
    console.error('Error getting posts:', error);
    throw error;
  }
};

// Image Management
export const uploadImage = async (file) => {
  try {
    const storageRef = ref(storage, `blog/images/${Date.now()}_${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const deleteImage = async (imageUrl) => {
  try {
    const imageRef = ref(storage, imageUrl);
    await deleteObject(imageRef);
    return true;
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error;
  }
};

// Comment Management
export const addComment = async (postId, commentData) => {
  try {
    const commentsRef = collection(db, COMMENTS_COLLECTION);
    const newComment = {
      ...commentData,
      postId,
      createdAt: serverTimestamp(),
      status: 'pending'
    };
    
    const docRef = await addDoc(commentsRef, newComment);
    return { id: docRef.id, ...newComment };
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

export const updateComment = async (commentId, status) => {
  try {
    const commentRef = doc(db, COMMENTS_COLLECTION, commentId);
    await updateDoc(commentRef, {
      status,
      updatedAt: serverTimestamp()
    });
    return true;
  } catch (error) {
    console.error('Error updating comment:', error);
    throw error;
  }
};

export const deleteComment = async (commentId) => {
  try {
    const commentRef = doc(db, COMMENTS_COLLECTION, commentId);
    await deleteDoc(commentRef);
    return true;
  } catch (error) {
    console.error('Error deleting comment:', error);
    throw error;
  }
};

export const getComments = async (postId, status = null) => {
  try {
    const commentsRef = collection(db, COMMENTS_COLLECTION);
    let q = query(
      commentsRef,
      where('postId', '==', postId),
      orderBy('createdAt', 'desc')
    );

    if (status) {
      q = query(q, where('status', '==', status));
    }

    const querySnapshot = await getDocs(q);
    const comments = [];
    querySnapshot.forEach((doc) => {
      comments.push({ id: doc.id, ...doc.data() });
    });

    return comments;
  } catch (error) {
    console.error('Error getting comments:', error);
    throw error;
  }
};

// Tag Management
export const getAllTags = async () => {
  try {
    const postsRef = collection(db, POSTS_COLLECTION);
    const querySnapshot = await getDocs(postsRef);
    const tags = new Set();
    
    querySnapshot.forEach((doc) => {
      const post = doc.data();
      if (post.tags) {
        post.tags.forEach(tag => tags.add(tag));
      }
    });
    
    return Array.from(tags);
  } catch (error) {
    console.error('Error getting tags:', error);
    throw error;
  }
};

// Analytics
export const incrementViews = async (postId) => {
  try {
    const postRef = doc(db, POSTS_COLLECTION, postId);
    await updateDoc(postRef, {
      views: increment(1)
    });
    return true;
  } catch (error) {
    console.error('Error incrementing views:', error);
    throw error;
  }
};