import React from 'react';
import { useTheme } from '@mui/material/styles';

const createSpinnerStyles = (size, color, variant = 'default', isDark = false) => {
  const borderWidth = Math.max(2, size / 10);
  
  const baseStyle = {
    display: 'inline-block',
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    position: 'relative',
  };

  // Dual ring effect with theme-aware colors
  const beforeAfterStyle = {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    border: `${borderWidth}px solid transparent`,
    animationDuration: '1.2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
  };

  // Variant-specific styles with theme consideration
  const variantStyles = {
    default: {
      before: {
        ...beforeAfterStyle,
        borderTop: `${borderWidth}px solid ${color}`,
        borderRight: `${borderWidth}px solid ${color}40`,
        animation: 'spin-primary 1.2s infinite cubic-bezier(0.68, -0.55, 0.265, 1.55)',
      },
      after: {
        ...beforeAfterStyle,
        borderBottom: `${borderWidth}px solid ${isDark ? color : `${color}60`}`,
        animation: 'spin-secondary 1.2s infinite cubic-bezier(0.68, -0.55, 0.265, 1.55)',
      },
    },
    button: {
      before: {
        ...beforeAfterStyle,
        borderTop: `${borderWidth}px solid currentColor`,
        borderRight: `${borderWidth}px solid currentColor`,
        opacity: 0.7,
        animation: 'spin-primary 1.2s infinite cubic-bezier(0.68, -0.55, 0.265, 1.55)',
      },
    },
    inline: {
      before: {
        ...beforeAfterStyle,
        borderTop: `${borderWidth}px solid ${color}`,
        borderRight: `${borderWidth}px solid ${color}40`,
        animation: 'spin-primary 1.2s infinite cubic-bezier(0.68, -0.55, 0.265, 1.55)',
      },
    },
  };

  return {
    base: baseStyle,
    variant: variantStyles[variant],
  };
};

const LoadingSpinner = ({ size = 40, customColor }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const color = customColor || (isDark ? theme.palette.primary.light : theme.palette.primary.main);
  const { base, variant } = createSpinnerStyles(size, color, 'default', isDark);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <style>
        {`
          @keyframes spin-primary {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          @keyframes spin-secondary {
            0% { transform: rotate(-30deg); }
            100% { transform: rotate(330deg); }
          }
        `}
      </style>
      <div style={base}>
        <div style={variant.before} />
        <div style={variant.after} />
      </div>
    </div>
  );
};

export const ButtonSpinner = ({ size = 16, noMargin = false }) => {
  const { base, variant } = createSpinnerStyles(size, 'currentColor', 'button');

  return (
    <div style={{ 
      position: 'relative', 
      display: 'inline-block',
      marginRight: noMargin ? 0 : '0.5rem',
      verticalAlign: 'middle',
    }}>
      <style>
        {`
          @keyframes spin-primary {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <div style={base}>
        <div style={variant.before} />
      </div>
    </div>
  );
};

export const InlineSpinner = ({ size = 20, noMargin = false }) => {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const color = isDark ? theme.palette.primary.light : theme.palette.primary.main;
  const { base, variant } = createSpinnerStyles(size, color, 'inline', isDark);

  return (
    <div style={{ 
      position: 'relative', 
      display: 'inline-block',
      margin: noMargin ? 0 : '0 0.5rem',
      verticalAlign: 'middle',
    }}>
      <style>
        {`
          @keyframes spin-primary {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <div style={base}>
        <div style={variant.before} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
